<template>
  <div class="center">
    <img src="@/assets/XFarmsLogo.png" class="logo" alt="" />
    <h3> Welcome to FutureOS </h3>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style>
.center {
  margin-top: 100px;
  display: block;
  top: 50%;
  left: 50%;
}

.logo {
  width: 500px;
  margin: 10px;
  max-width: 100%;
  transform: translateY(-10px);
}
</style>